<template>
<section class="invoice-add-wrapper">
    <b-row class="invoice-add">

        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
            <b-form @submit.prevent>
                <b-card no-body class="invoice-preview-card">

                    <b-card-body class="invoice-padding form-item-section">

                        <b-row>
                            <b-col lg="8" sm="12">
                                <label>計劃編號</label>
                                <v-select v-model="selected_plan" :options="plan"/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>計劃類型</label>
                                <b-form-input id="plan_type" v-model="membership.plan_type" disabled/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>開始日期</label>
                                <flat-pickr v-model="membership.start" class="form-control" :config="{ dateFormat: 'Y-m-d'}" placeholder="YYYY-MM-DD"/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>結束日期</label>
                                <flat-pickr v-model="membership.expire" class="form-control" :config="{ dateFormat: 'Y-m-d'}" placeholder="YYYY-MM-DD"/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>計劃日數</label>
                                <b-form-input id="period" v-model="membership.period" disabled/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>包含課堂 Credit</label>
                                <b-form-input id="class_credit" v-model="membership.class_credit"/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>付款日</label>
                                <b-form-input id="payment_day" v-model="membership.payment_day"/>
                            </b-col>
                            <b-col lg="4" sm="6">
                                <label>計劃價錢</label>
                                <b-form-input id="amount" v-model="membership.amount" disabled/>
                            </b-col>

                            <b-col lg="12" sm="12">
                                <br>
                                    <h5>
                                        <u>TEAMS AND CONDITIONS</u>
                                    </h5>
                                    <nl2br class="tnc" tag="div" v-if="terms !== null" :text="terms"/>
                                </b-col>

                                <b-col lg="12" sm="12">
                                    <br>簽名欄
                                        <VueSignaturePad ref="signaturePad" height="150px" :options="{ dotSize: 2, throttle: 16,backgroundColor: 'rgba(200,200,200,1)',onBegin, onEnd }"/>
                                    </b-col>
                                </b-row>
                            </b-card-body>

                            <!-- Invoice Description: Total -->

                            <!-- Spacer -->
                            <hr class="invoice-spacing">
                                <!-- Note -->
                                <b-card-body class="invoice-padding pt-0">
                                    <b-row>
                                        <b-col sm="9">
                                            <span class="font-weight-bold">Note:
                                            </span>
                                            <b-form-textarea v-model="membership.note"/>

                                        </b-col>
                                        <b-col sm="3" v-if="membership.has_commission == 'Y'">
                                            <b-form-group label="For Internal Use Only" label-for="payment-method">
                                                <v-select v-model="selected_staff" :options="staffs" class="payment-selector" :clearable="false"/>
                                            </b-form-group>
                                            <b-form-input v-mask="'###'" v-model="percentage" trim/>
                                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" size="sm" class="mt-1" block @click="add_commission()" variant="primary">
                                                <font-awesome-icon icon="fa-solid fa-plus" class="mr-1"/>
                                                Add Staff
                                            </b-button>
                                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" size="sm" class="mt-1" block @click="clear_commission()" variant="outline-danger">
                                                <font-awesome-icon icon="fa-solid fa-times" class="mr-1"/>
                                                Clear
                                            </b-button>
                                            <hr/>
                                            <div v-for="(record, index) in commission">
                                                {{record.user.label}}
                                                -
                                                {{record.percentage}}%
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                        </b-form>
                    </b-col>

                    <!-- Right Col: Card -->
                    <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">

                        <!-- Action Buttons -->
                        <b-card>

                            <!-- Button: Send Invoice -->
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click="submit_invoice()">
                                Submit
                            </b-button>

                            <!-- Button: DOwnload -->
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="back()">
                                Back
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" class="mt-1" block @click="clear()" variant="outline-warning">
                                Empty Signature
                            </b-button>
                            <hr class="my-2"/>
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" class="mt-1" block @click="send_paylink()" variant="info">
                                <font-awesome-icon icon="fa-solid fa-paper-plane" class="mr-1"/>
                                PAY LINK
                            </b-button>
                        </b-card>

                        <!-- Payment Method -->
                        <div class="mt-2">
                            <b-card>
                                <b-form-group label="Accept Payment Via" label-for="payment-method">
                                    <v-select
                                        v-model="selected_payment.method"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="paymentMethods"
                                        input-id="payment-method"
                                        class="payment-selector"
                                        :clearable="false"/>
                                </b-form-group>
                                <b-form-input v-model="selected_payment.amount" placeholder="Payment Amount" type="number" class="mb-2"/>
                                <div v-if="selected_payment.method.value == 'STRIPE'">
                                    <hr class="mt-1"/>
                                    <b-form-group label="Credit Card Number" label-for="card">
                                        <b-form-input id="card" v-mask="'#### #### #### ####'" v-model="card.number" trim/>
                                    </b-form-group>

                                    <b-form-group label="Expiry Date (YYYY-MM)" label-for="Expiry Date">
                                        <b-form-input id="expire" v-mask="'####-##'" v-model="card.expire" trim/>

                                    </b-form-group>

                                    <b-form-group label="CVC" label-for="cvc">
                                        <b-form-input id="cvc" v-mask="'###'" v-model="card.cvc" trim/>

                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-checkbox checked="false" v-model="card.save" name="check-button" switch inline>
                                            使用此卡作日後付款
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>
                            </div>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mb-75"
                                block
                                @click="add_payment()"
                                :disabled="selected_payment.amount < 1 || processing === true">
                                {{loading}}
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-warning" class="mb-75" block @click="clear_payment()">
                                Clear Payment
                            </b-button>
                            <div v-for="(payment, index) in membership.payment_method">
                                {{payment.method.value}}
                                - $
                                {{payment.amount}}
                            </div>
                            <hr/>
                            <h4>Paid 已付 : $
                                {{payment_total}}</h4>
                            <h5 style="color:red" v-if="membership.amount - this.payment_total > 0">Dues 結欠 : $
                                {{membership.amount - this.payment_total}}</h5>
                        </b-card>
                        <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing -->

                    </div>
                </b-col>
            </b-row>
        </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BFormSpinbutton, BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import router from '@/router'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Nl2br from 'vue-nl2br'
import VueSignaturePad from 'vue-signature-pad';

export default {
  components: {
    ToastificationContent,
    router,
    Nl2br,
    BFormSpinbutton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    moment,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      terms : JSON.parse(localStorage.getItem('userData')).terms.membership,
      staffs : [],
      card : {
        save:false,
        number:null,
        expire:null,
        cvc:null,
        amount:0,
        member_id:null
      },
      selected_plan:null,
      selected_staff:null,
      commission:[],
      membership : {
        amount:null,
        start:null,
        note:null,
        charge_after_expire:null,
        has_commission:null,
        expire:null,
        code:null,
        signature:null,
        period:null,
        plan_type:null,
        payment_day:null,
        class_credit:null,
        payment_method:[],
      },
      percentage:null,
      itemFormBlankItem: { qty : 1},
      paymentMethods: [],
      plan: [],
      selected_payment : {
        method : {
          label : "",
          value : ""
        },
        amount : null
      },
      invoice: {
        subtotal : 0,
        discount : 0,
        total : 0
      },
      paymentOptions:[],
      processing : false,
      itemsOptions:[],
      invoiceData:{
        items:[],
        payments:[],
        total_amount:0,
      }
    }
  },

  created() {
  //  window.addEventListener('resize', this.initTrHeight)
    this.get_plan()
    this.get_staffs()
    this.get_payment_method()
  },
  watch: {
    'membership.start' : {
      handler(newValue, oldValue) {
      //  console.log(newValue)
        let period = this.membership.period.split(" ")
        console.log(period)
        this.membership.expire = moment(newValue).add(period[0], period[1]).format('YYYY-MM-DD')
      }
    },
  selected_plan : {
    handler(newValue, oldValue) {
      console.log(newValue)
      this.membership.plan_type = newValue.type
      this.membership.amount = newValue.amount
      this.membership.code = newValue.label

      this.membership.has_commission = newValue.has_commission
      this.membership.charge_after_expire = newValue.dues_charge_after_expire
      //calc start date and expire date
      this.membership.start = moment( new Date().getTime() ).format('dddd YYYY-MM-DD')
      this.membership.expire = moment().add(newValue.period_quantity, newValue.period_unit).format('YYYY-MM-DD')
      this.membership.payment_day = moment().format('DD')
      this.membership.period = newValue.period_quantity + " " + newValue.period_unit
      this.membership.class_credit = newValue.class_credit
    }
  },
  start : {
      handler(newValue, oldValue) {
        this.membership.expire = moment( newValue ).add(this.selected_plan.period_quantity, this.selected_plan.period_unit).format('YYYY-MM-DD')
        this.membership.payment_day = moment( newValue ).add(this.selected_plan.period_quantity, this.selected_plan.period_unit).format('DD')
      }
  }
},
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed : {
    loading() {
      if (this.processing === true) {
        return "Loading..."
      } else {
        return "Add Payment"
      }
    },
    invoice_total() {
      let total = 0
      this.invoiceData.items.forEach (item => {
       // if (item.itemTitle.price && item.itemTitle.qty > 0 && item.itemTitle.price > 0) {
      //
       // }
       if (item.itemTitle) {
          total += item.itemTitle.price * item.qty
       }
       // console.log(item)
      })
      this.invoiceData.total_amount = total
      return total
    },
    payment_total() {
      let total = 0
      this.membership.payment_method.forEach (item => {
        if (item.amount && item.amount > 0 ) {
          total += parseInt(item.amount)
        }
      })
      return parseInt(total)
    }
  },
  methods: {
    clear_payment() {
      let pass = true
      this.membership.payment_method.forEach (item => {
        if (item.method.value == "STRIPE" ) {
          pass = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Error',
              icon: 'XIcon',
            variant: 'danger',
              text: "信用卡已經付款, 不能消除",
            },
          })
          return
        }
      })
      if (pass === true) {
          this.membership.payment_method = [];
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    onBegin() {
      console.log('=== Begin ===');
    },
    onEnd() {
      console.log('=== End ===');
    },
    send_paylink() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+ router.currentRoute.params.id +'/membership/paylink/?center_id='+this.$store.getters['center/currentCenterId'],this.membership)
      .then(res => {
              if (res.data.result === true) {
               this.$toast({
               component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                  },
                })
              this.back()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                  variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })

    },
    submit_invoice() {
      if ( this.commission.length > 0 ) {
        this.membership.commission = this.commission
      }
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      this.membership.signature = data
      this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+ router.currentRoute.params.id +'/membership/add/?center_id='+this.$store.getters['center/currentCenterId'],this.membership)
      .then(res => {
              if (res.data.result === true) {
               this.$toast({
               component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                  },
                })
              this.back()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                  variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })

    },
    clear_commission() {
      this.commission = []
    },
    add_commission() {
      let exist_total_percentage = parseInt(0)
      this.commission.forEach((item, i) => {
        exist_total_percentage += parseInt(item.percentage)
      });
      if ( (exist_total_percentage + parseInt(this.percentage)) > 100) {
        return
      }
      let result = {
        user : this.selected_staff,
        percentage : this.percentage
      }
      this.commission.push(result)
      this.percentage = null
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    async add_payment() {
      if ( (this.membership.amount - this.payment_total ) < this.selected_payment.amount ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: '金額大於選擇項目',
          },
        })
        return
      }
      this.processing = true
      switch(this.selected_payment.method.value) {
        case "STRIPE":
        this.card.amount = this.selected_payment.amount
        this.card.member_id = router.currentRoute.params.id
        console.log("stripe")
        await this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/stripe/' + this.$store.getters['center/currentCenterId'] + '/', this.card)
          .then(res => {
            if (res.data.result === true) {
              this.selected_payment.stripe_id = res.data.stripe_id
              this.$toast({
              component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
              this.processing = false
              this.card.number = null
              this.card.expire = null
              this.card.save = false
              this.card.cvc = null
              this.card.amount = 0
              this.add_payment_2()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              },
              {
                timeout:999999,
              })
              this.show = false
              this.processing = false
            }
          })
        break;
        default:
          this.add_payment_2()
        break;

      }

    },
    add_payment_2() {
      this.membership.payment_method.push(this.selected_payment)
      this.selected_payment = {
        method : {
          label : "",
          value : "",
          stripe_id : null
        },
        amount : null
      }
      this.processing = false
    },
    back() {
      this.$router.back()
    },
    updateItemForm (index, val) {
    //  console.log(val)
    console.clear()
      this.invoiceData.items[index].itemTitle = val
      console.log(this.invoiceData)
    //  this.invoiceData.items[index].price = val.price
    //  this.invoiceData.items[index].qty = qty
    },
    get_plan() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/plan/')
        .then(res => {
          this.plan = res.data
          console.log(this.plan)
        })
    },
    get_product() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/products/')
        .then(res => {
          this.itemsOptions = res.data
        })
    },
    get_staffs() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/staffs/select/?mode=all')
        .then(res => {
          this.staffs = res.data
        })
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))



      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
